import logo from './logo.svg';
import './App.css';
import Screen from './components/screen';

function App() {
  const backgroundImage = 'bg.jpg'
  return (
    <div style={{
      backgroundImage: `url(${backgroundImage})`,
      background: `url(${backgroundImage})`,
      backgroundColor: 'black',
      height: '100vh'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%'       
      }}>
        <Screen/>
      </div>
    </div>
  );
}

export default App;
