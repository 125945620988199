import {useState} from 'react'

function BtnsRender({answer, setAnswer}) {
    const [btnOrder, setBtnOrder] = useState(true)
    const [yesBtnColor, setYesBtnColor] = useState('#527853')
    const [btnTextColor, setBtnTextColor] = useState('white')
    const intrusiveThoughts = [
        'Cmon bae there is only one correct answer :)',
        'Duhh press the button already',
        'Good luck saying no :))',
    ]
    const [thought, setThought] = useState(null)

    const getRandomThought = () =>{
        return intrusiveThoughts[Math.floor(Math.random() * 3)]
    }

  return (
    <div>{
        <div style={{
            fontSize: '11px'
        }}>
            {thought}
        </div> 
        }
    <div>
        {
            btnOrder ?
            <>
                <button style={{
                    padding: '10px 20px',
                    margin: '10px',
                    backgroundColor: yesBtnColor,
                    color: btnTextColor,
                    fontSize: '24px',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}
                onClick={(e)=>setAnswer(true)}
                onMouseEnter={(e)=>{setYesBtnColor('#C3E2C2')
            setBtnTextColor('black')
            setThought(getRandomThought())
                }}
                onMouseLeave={(e)=>{setYesBtnColor('#527853')
            setBtnTextColor('white')
        setThought(null)}}
                >Yes</button>
                <button
                style={{
                    padding: '10px 20px',
                    margin: '10px',
                    backgroundColor: '#CE5A67',
                    fontSize: '24px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    color: 'white'
                }}
                onMouseOver={(e)=>setBtnOrder(!btnOrder)}>No</button>
            </> :
            <>
                <button
                style={{
                    padding: '10px 20px',
                    margin: '10px',
                    backgroundColor: '#CE5A67',
                    fontSize: '24px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    color: 'white'
                }}
                onMouseOver={(e)=>setBtnOrder(!btnOrder)}>No</button>
                <button style={{
                    padding: '10px 20px',
                    margin: '10px',
                    backgroundColor: yesBtnColor,
                    color: btnTextColor,
                    fontSize: '24px',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}
                onClick={(e)=>setAnswer(true)}
                onMouseEnter={(e)=>{setYesBtnColor('#C3E2C2')
            setBtnTextColor('black')
            setThought(getRandomThought())
                }}
                onMouseLeave={(e)=>{setYesBtnColor('#527853')
            setBtnTextColor('white')
        setThought(null)}}
                >Yes</button>
            </>
        }

    </div>
    </div>
  )
}

export default BtnsRender
