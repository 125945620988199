import React, { useState } from 'react'
import BtnsRender from './btnsrender'

function Screen() {
    const [answer, setAnswer] = useState(false)
  return (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFB996',
        border: 'solid black 2px',
        marginLeft: '15%',
        marginRight: '15%',
        borderRadius: '20px',
        height: '70%',
        padding: '30px'
    }}>
        {
            !answer ?
            <>
                <h1 style={{
            textAlign: 'center',
            color: '#643843',
            fontSize: '48px',
            fontFamily: 'Valentine Mine, sans-serif'
        }}>Nidhi, will you be my valentine?</h1>
        <BtnsRender answer={answer}
        setAnswer={setAnswer}/>
        <img src='me.jpeg' width={200}></img>
            </> :
            <>
            <h1 style={{
            textAlign: 'center',
            color: '#643843',
            fontSize: '48px',
            fontFamily: 'Valentine Mine, sans-serif'
        }}>LESGOOOOOOOOO THIS COULD BE US<br/>(you're stoopid cuz you said yes btw)</h1>
        <img src='us.jpeg' width={200}></img>
            </>
        }
        
    </div>
  )
}

export default Screen
